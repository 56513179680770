import { RealtimeChannel } from '@supabase/supabase-js'
import { useDebounceFn } from '@vueuse/core'
import { defineStore } from 'pinia'
import { ref } from 'vue'

import supabase from '@/supabase'
import type { Callback } from '@/types/supabase'

export type Table = 'profiles' | 'feedback_messages' | 'feedback_messages_read' | 'cars' | 'connection_requests' | 'connections' | 'profiles_read' | 'connections_read' | 'cars_read'
export type Component = 'ProfilesManagementPage' | 'UserFeedbackPage' | 'CarsPage' | 'DashboardPage' | 'MyNetworkPage' | 'DealersView' | 'RequestsView' | 'ConnectionsView' | 'TheSidebar'

export const useRealtimeStore = defineStore('realtime', () => {
  const callbacks = ref<Map<Table, Map<Component, Callback>>>(new Map())
  const realtimeChannel = ref<RealtimeChannel | null>(null)

  const subscribeToPostgresChanges = (): void => {
    if (realtimeChannel.value) return
    realtimeChannel.value = supabase.channel('all_postgres_changes')
      .on('postgres_changes', { event: '*', schema: '*' }, payload =>
        callbacks.value
          .get(payload.table as Table)
          ?.forEach(callback => callback(payload)))
      .subscribe()
  }

  const unsubscribeFromPostgresChanges = (): void => {
    realtimeChannel.value?.unsubscribe()
      .finally(() => realtimeChannel.value = null)
  }

  const subscribe = (component: Component, tables: Table[], callback: Callback): void => {
    tables.forEach(table => {
      const map = callbacks.value.get(table) ?? new Map()
      map.set(component, useDebounceFn(callback, 50))
      callbacks.value.set(table, map)
    })
    subscribeToPostgresChanges()
  }

  const unsubscribe = (component: Component): void => {
    callbacks.value.forEach(map => map.delete(component))
    if (Array.from(callbacks.value.values()).every(map => map.size === 0)) {
      unsubscribeFromPostgresChanges()
    }
  }

  const onUnmounted = async (): Promise<void> => unsubscribeFromPostgresChanges()

  return {
    subscribe,
    unsubscribe,
    onUnmounted
  }
})
