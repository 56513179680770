import { defineStore } from 'pinia'
import { ref, watch } from 'vue'

import profilesApi from '@/api/database/profiles-api'
import { useAuthStore } from '@/stores/auth-store'
import supabase from '@/supabase'
import type { AddUserRole, DeleteUser, GetAllUserRoles, GetUserRoles, Profile, ProfileWithNewProfileFlag, RemoveUserRole } from '@/types/supabase'

export const useProfilesStore = defineStore('profiles', () => {
  const currentProfile = ref<Profile | null>(null)
  const profiles = ref<ProfileWithNewProfileFlag[] | null>(null)
  const count = ref<number | null>(null)
  const sortBy = ref<SortBy<Profile>>({
    key: 'created_at',
    ascending: false
  })
  const page = ref(1)
  const limit = ref<number | null>(10)
  const userRoles = ref(new Map<string, Role[]>())
  const authStore = useAuthStore()

  watch(page, () => fetchAllProfiles())
  watch([limit, sortBy], () => {
    page.value = 1
    fetchAllProfiles()
  })

  const fetchAllProfiles = async function (): Promise<void> {
    profiles.value = await profilesApi.fetchAllProfilesWithNewProfileFlag(page.value, limit.value, sortBy.value)
  }
  const fetchProfilesCount = async function (): Promise<void> {
    count.value = await profilesApi.fetchProfilesCount()
  }
  const updateUserProfile = async function (userId: string, firstName: string | null, lastName: string | null, phone: string | null, companyName: string | null, aboutCompany: string | null, avatarImageId: string | null): Promise<Profile> {
    const updatedProfile = await profilesApi.updateProfile(userId, firstName, lastName, phone, companyName, aboutCompany, avatarImageId)
    if (authStore.userId === userId) currentProfile.value = updatedProfile
    await fetchAllProfiles()
    return updatedProfile
  }
  const toggleUserRole = async function ({ userId, role }: { userId: string, role: Role }): Promise<void> {
    const { data: userRoles, error } = await supabase.rpc<'get_user_roles', GetUserRoles>('get_user_roles', { uid: userId })
    if (error) throw error
    const hasRole = (userRoles as Role[]).includes(role)
    const { error: rpcError } = hasRole ?
      await supabase.rpc<'remove_user_role', RemoveUserRole>('remove_user_role', { uid: userId, user_role: role }) :
      await supabase.rpc<'add_user_role', AddUserRole>('add_user_role', { uid: userId, user_role: role })
    if (rpcError) throw rpcError
    await fetchAllUserRoles()
  }
  const fetchAllUserRoles = async function (): Promise<void> {
    const { data, error } = await supabase.rpc<'get_all_user_roles', GetAllUserRoles>('get_all_user_roles')
    if (error) throw error
    const roles = Object.entries(data || {})
      .map(([key, value]) => [key, value ?? []] as [string, Role[]])
    userRoles.value = new Map(roles)
  }
  const deleteUser = async function (userId: string): Promise<void> {
    const { error } = await supabase.rpc<'delete_user', DeleteUser>('delete_user', { uid: userId })
    if (error) throw error
    await fetchAllUserRoles()
  }
  const fetchCurrentProfile = async function (userId: string): Promise<Profile> {
    currentProfile.value = await profilesApi.fetchProfile(userId)
    return currentProfile.value
  }

  watch(() => authStore.userId, (newUserId) => {
    if (newUserId) {
      fetchCurrentProfile(newUserId)
    }
  }, { immediate: true })

  return {
    currentProfile,
    profiles,
    count,
    sortBy,
    page,
    limit,
    userRoles,
    fetchAllProfiles,
    fetchProfilesCount,
    updateUserProfile,
    toggleUserRole,
    fetchAllUserRoles,
    deleteUser
  }
})
