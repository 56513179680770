import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useRouter } from 'vue-router'

import { captureException } from '@/sentry'

const SLIDE_IN_OUT_DURATION = 1000

export const useToastsStore = defineStore('toasts', () => {
  const toasts = ref<Toast[]>([])

  const router = useRouter()

  const clearToast = function (toastId: string): void {
    toasts.value = toasts.value.filter(t => toastId !== t.id)
  }
  const info = function (title: string, message?: string, action?: Toast['action'], timeout: number = 5000): void {
    const toast = createToast(title, 'info', timeout, message, action)
    toasts.value.push(toast)
    setTimeout(() => clearToast(toast.id), timeout)
  }
  const success = function (title: string, message?: string, action?: Toast['action'], timeout: number = 5000): void {
    const toast = createToast(title, 'success', timeout, message, action)
    toasts.value.push(toast)
    setTimeout(() => clearToast(toast.id), timeout)
  }
  const error = function (title: string, message: string, error: unknown, timeout: number = 10000): void {
    const sentryEventId = captureException(error)
    const toast = createToast(title, 'error', timeout, message, {
      text: 'Ich benötige Hilfe',
      handler: () => {
        toast.dismiss()
        router.push({ path: '/feedback', query: { sentryEventId } })
      }
    })
    toasts.value.push(toast)
    setTimeout(() => clearToast(toast.id), timeout)
  }
  const createToast = function (title: string, type: Toast['type'], timeout: number, message?: string, action?: Toast['action']): Toast {
    const id = crypto.randomUUID()
    return {
      id,
      title,
      message,
      type,
      timeout: timeout - (2 * SLIDE_IN_OUT_DURATION),
      start: new Date(new Date().getTime() + SLIDE_IN_OUT_DURATION),
      dismiss: () => clearToast(id),
      action: action ? {
        text: action.text, handler: () => {
          action.handler()
          clearToast(id)
        }
      } : undefined
    }
  }
  return {
    toasts,
    info,
    success,
    error
  }
})
