import supabase from '@/supabase'
import type { Profile, ProfileWithConnectionInformation, ProfileWithNewProfileFlag } from '@/types/supabase'

class ProfilesApi {

  public async fetchProfile(userId: string): Promise<Profile> {
    const { data, error } = await supabase.from('profiles')
      .select('*')
      .eq('id', userId)
      .order('email', { ascending: true })
      .single()
    if (error) throw error
    return data
  }

  public async fetchAllProfilesWithNewProfileFlag(page: number, limit: number | null, sortBy: SortBy<Profile>): Promise<ProfileWithNewProfileFlag[]> {
    const query = supabase.from('profiles')
      .select('*, new_profiles (profile_id)')
      .order(sortBy.key, { ascending: sortBy.ascending })
      .order('id', { ascending: false })
    if (limit !== null) {
      query.range((page - 1) * limit, page * limit - 1)
    }
    const { data, error } = await query
    if (error) throw error
    return data
      .map(profile => ({
        ...profile,
        new_profile: profile.new_profiles !== null
      }))
  }

  public async fetchAllProfilesWithConnectionInformation(page: number, limit: number | null, sortBy: SortBy<Profile>, currentUserId: string): Promise<ProfileWithConnectionInformation[]> {
    const query = supabase.from('profiles')
      .select(`*,
         connection_requests_to:connection_requests!connection_requests_to_fkey (id, to),
         connection_requests_created_by:connection_requests!connection_requests_created_by_fkey (id, created_by),
         connections_created_by:connections!connections_created_by_fkey (id, created_by),
         connections_to:connections!connections_to_fkey (id, to),
         profiles_read!profiles_read_profile_id_fkey(profile_id)
         `)
      .order(sortBy.key, { ascending: sortBy.ascending })
      .neq('id', currentUserId)
    if (limit !== null) {
      query.range((page - 1) * limit, page * limit - 1)
    }
    const { data, error } = await query
    if (error) throw error
    return data
      .map(profile => {
        const con = profile.connections_created_by.find(connection => connection.created_by === profile.id) ?? profile.connections_to.find(connection => connection.to === profile.id) ?? null
        const direction = profile.connection_requests_to.find(request => request.to === profile.id) !== undefined ? 'outgoing' : 'incoming'
        const connectionRequest = profile.connection_requests_to.find(request => request.to === profile.id) ?? profile.connection_requests_created_by.find(request => request.created_by === profile.id) ?? null
        return {
          ...profile,
          connection_request: connectionRequest !== null ? { id: connectionRequest.id, direction } : null,
          connection: con !== null ? { id: con.id } : null,
          is_unread: profile.profiles_read.length === 0
        }})
  }

  public async updateProfile(userId: string, firstName: string | null, lastName: string | null, phone: string | null, companyName: string | null, aboutCompany: string | null, avatarImageId: string | null): Promise<Profile> {
    const { data, error } = await supabase.from('profiles')
      .update({
        first_name: firstName,
        last_name: lastName,
        phone,
        company_name: companyName,
        about_company: aboutCompany,
        avatar_image_id: avatarImageId
      })
      .eq('id', userId)
      .select()
      .single()
    if (error) throw error
    return data
  }

  public async fetchProfilesCount(): Promise<number> {
    const { count, error } = await supabase
      .from('profiles')
      .select('*', { count: 'exact', head: true })
    if (error) throw error
    return count ?? 0
  }

  async fetchAllProfileWithConnectionInformationCount(currentUserId: string): Promise<number> {
    const { count, error } = await supabase
      .from('profiles')
      .select('*', { count: 'exact', head: true })
      .neq('id', currentUserId)
    if (error) throw error
    return count ?? 0
  }
}

export default new ProfilesApi()
