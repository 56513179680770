import { createRouter, createWebHistory, type RouteLocationNormalizedLoaded } from 'vue-router'

import { authGuard } from '@/auth/auth-guard'
import LandingPage from '@/pages/LandingPage.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      component: LandingPage,
    },
    {
      path: '/dashboard',
      component: () => import('@/pages/DashboardPage.vue'),
      meta: {
        requiresRole: 'user'
      }
    },
    {
      path: '/my-profile',
      component: () => import('@/pages/MyProfilePage.vue'),
      meta: {
        breadcrumbs: () => [
          { text: 'Mein Profil', to: '/my-profile' },
        ]
      }
    },
    {
      path: '/cars',
      component: () => import('@/pages/CarsPage.vue'),
      meta: {
        requiresRole: 'user',
        breadcrumbs: () => [
          { text: 'Fahrzeuge', to: '/cars' },
        ]
      }
    },
    {
      path: '/cars/new',
      component: () => import('@/pages/NewCarPage.vue'),
      meta: {
        requiresRole: 'user',
        breadcrumbs: () => [
          { text: 'Fahrzeuge', to: '/cars' },
          { text: 'Neues Fahrzeug', to: '/cars/new' },
        ]
      },
    },
    {
      path: '/cars/:id/edit',
      component: () => import('@/pages/EditCarPage.vue'),
      meta: {
        requiresRole: 'user',
        breadcrumbs: (route: RouteLocationNormalizedLoaded) => {
          const id = Array.isArray(route.params.id) ? route.params.id[0] : route.params.id
          return [
            { text: 'Fahrzeuge', to: '/cars' },
            { text: id, to: `/cars/${id}` },
            { text: 'Fahrzeug bearbeiten', to: `/cars/${id}/edit` },
          ]
        }
      }
    },
    {
      path: '/login',
      component: () => import('@/pages/LoginPage.vue'),
    },
    {
      path: '/reset-password',
      component: () => import('@/pages/ResetPasswordPage.vue'),
    },
    {
      path: '/reset-password/success',
      component: () => import('@/pages/ResetPasswordSuccessPage.vue'),
    },
    {
      path: '/reset-password/request',
      component: () => import('@/pages/ResetPasswordRequestPage.vue'),
    },
    {
      path: '/reset-password/request-success',
      component: () => import('@/pages/ResetPasswordRequestSuccessPage.vue'),
    },
    {
      path: '/signup',
      component: () => import('@/pages/SignupPage.vue'),
    },
    {
      path: '/signup/success',
      component: () => import('@/pages/SignupSuccessPage.vue'),
    },
    {
      path: '/confirm-email/success',
      component: () => import('@/pages/ConfirmEmailSuccessPage.vue'),
    },
    {
      path: '/confirm-email/sent',
      component: () => import('@/pages/ConfirmEmailSendSuccessPage.vue'),
    },
    {
      path: '/confirm-email/resend',
      component: () => import('@/pages/ConfirmEmailResendPage.vue'),
    },
    {
      path: '/profiles-management',
      component: () => import('@/pages/ProfilesManagementPage.vue'),
      meta: {
        requiresRole: 'admin',
        breadcrumbs: () => [
          { text: 'Benutzerverwaltung', to: '/profiles-management' },
        ]
      },
    },
    {
      path: '/user-feedback',
      component: () => import('@/pages/UserFeedbackPage.vue'),
      meta: {
        requiresRole: 'admin',
        breadcrumbs: () => [
          { text: 'Nutzerfeedback', to: '/user-feedback' },
        ]
      },
    },
    {
      path: '/cars/:id',
      component: () => import('@/pages/CarDetailPage.vue'),
      meta: {
        requiresRole: 'user',
        breadcrumbs: (route: RouteLocationNormalizedLoaded) => {
          const id = Array.isArray(route.params.id) ? route.params.id[0] : route.params.id
          return [
            { text: 'Fahrzeuge', to: '/cars' },
            { text: id, to: `/cars/${id}` },
          ]
        }
      }
    },
    {
      path: '/feedback',
      component: () => import('@/pages/FeedbackPage.vue'),
    },
    {
      path: '/feedback/sent-success',
      component: () => import('@/pages/FeedbackSentSuccessPage.vue'),
    },
    {
      path: '/my-network',
      component: () => import('@/pages/MyNetworkPage.vue'),
      beforeEnter: (to, _, next) => {
        if (to.path === '/my-network') next('/my-network/connections')
        else next()
      },
      meta: {
        requiresRole: 'user',
        breadcrumbs: () => {
          return [
            { text: 'Mein Netzwerk', to: '/my-network' }
          ]
        }
      },
      children: [
        {
          path: 'connections',
          component: () => import('@/components/network/ConnectionsView.vue'),
        },
        {
          path: 'dealers',
          component: () => import('@/components/network/DealersView.vue'),
        },
        {
          path: 'requests',
          component: () => import('@/components/network/RequestsView.vue'),
        }
      ]
    },
    {
      path: '/forbidden',
      component: () => import('@/pages/ForbiddenPage.vue'),
    },
    {
      path: '/not-found',
      component: () => import('@/pages/NotFoundPage.vue'),
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: '/not-found'
    },
  ]
})

router.beforeEach(authGuard)

export default router
