<template>
  <CarvisInput
    :id="id"
    input="div"
  >
    <div class="flex h-full space-x-1 items-center">
      <div
        v-if="prependUnit"
        class="text-gray-600"
      >
        {{ prepend }}
      </div>
      <input
        :id="id"
        class="nested-input-field"
        :type="type"
        :unit="unit"
        :value="modelValue"
        @blur="$emit('blur')"
        @input="onInput"
      >
      <CarvisDropdown
        :id="`${id}_unit`"
        class="!p-0 !ring-0 !shadow-none !bg-transparent"
        hide-error-row
        :model-value="unit"
        :options="unitOptions"
        @update:model-value="onUpdate"
      />
    </div>
  </CarvisInput>
</template>

<script setup lang="ts" generic="T, U">
import { computed } from 'vue'

const emit = defineEmits([
  'update:modelValue',
  'update:unit',
  'blur'
])

const props = defineProps<{
  id: string,
  modelValue: T
  unit: U
  unitOptions: { value: U; label?: string, asPrefix?: string }[]
  prependUnit?: boolean
  type?: string
}>()

const onUpdate = (value: U): void => {
  emit('update:unit', value)
  emit('blur')
}

const prepend = computed(() => {
  const option = props.unitOptions.find(option => option.value === props.unit)
  return option?.asPrefix ?? option?.label ?? ''
})

const onInput = (event: Event): void => {
  const { value } = event.target as HTMLInputElement
  emit('update:modelValue', value)
}
</script>
