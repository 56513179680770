import supabase from '@/supabase'
import { type FetchNotificationCounts, type NotificationCounts } from '@/types/supabase'

class NotificationsApi {

  public async fetchNotificationCounts(): Promise<NotificationCounts> {
    const { data, error } = await supabase.rpc<'fetch_notification_counts', FetchNotificationCounts>('fetch_notification_counts')
    if (error) throw error
    return data
  }
}

export default new NotificationsApi()
