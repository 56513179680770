<template>
  <div class="flex-shrink-0">
    <label :for="id">
      <div
        v-if="label || $slots.hint"
        class="flex justify-between"
      >
        <CarvisLabel>
          {{ label }}
        </CarvisLabel>
        <CarvisLabel>
          <slot name="hint" />
        </CarvisLabel>
      </div>
    </label>
    <component
      :is="input"
      :id="id"
      :aria-label="label"
      v-bind="$attrs"
      class="rounded-md border-0 py-1.5 shadow-sm ring-1 text-base md:text-sm disabled:opacity-30 disabled:cursor-not-allowed leading-6 ring-inset outline-none px-2 h-9"
      :class="{
        'w-full': block,
        'block': block,
        'ring-blue bg-white': !error,
        'bg-error bg-opacity-10 !ring-error ring-2': error,
        'hover:ring-2 focus:ring-2 focus:ring-blue hover:ring-blue': !disabled
      }"
      :disabled="disabled || loading"
      :type="type"
      :value="modelValue"
      @blur="$emit('blur')"
      @change="onChange"
      @input="onInput"
    >
      <slot />
    </component>
    <div
      v-if="!hideErrorRow"
      aria-label="error-message"
      class="flex justify-between text-xs mx-1 italic h-4 mt-0.5"
      :class="{ 'text-error': error }"
    >
      <p>
        {{ error }}
      </p>
      <p v-if="counter !== undefined">
        {{ `${inputLength}/${counter}` }}
      </p>
    </div>
  </div>
</template>

<script setup lang="ts" generic="T">
import { type Component, computed } from 'vue'

const emit = defineEmits(['update:modelValue', 'blur', 'change'])

const props = defineProps<{
  id: string
  input: string | Component,
  block?: boolean,
  error?: string
  disabled?: boolean
  loading?: boolean
  label?: string
  type?: string
  counter?: number
  modelValue?: T
  hideErrorRow?: boolean
}>()

const inputLength = computed(() => props.modelValue === undefined || props.modelValue === null ? 0 : new String(props.modelValue).length)

const onInput = (event: InputEvent): void => {
  const { value } = event.target as HTMLInputElement
  emit('update:modelValue', value)
}

const onChange = (event: InputEvent): void => {
  const { value } = event.target as HTMLInputElement
  emit('change', value)
}
</script>
