<template>
  <div class="flex justify-between text-gray-700 pb-2 md:pb-4">
    <span
      v-if="lg"
      class="text-sm items-center space-x-2 flex"
    >
      <CarvisDropdown
        id="limit"
        hide-error-row
        :model-value="limit"
        :options="limitOptions"
        @update:model-value="$emit('update:limit', $event)"
      />
      <span>
        {{ itemDescription }} pro Seite
      </span>
    </span>
    <div
      v-if="!lg"
      class="flex items-center justify-between w-full space-x-2"
    >
      <CarvisDropdown
        id="limit"
        hide-error-row
        :model-value="limit"
        :options="limitOptions"
        @update:model-value="$emit('update:limit', $event)"
      >
        Limit
      </CarvisDropdown>
      <div class="flex items-center space-x-2">
        <CarvisDropdown
          id="sortBy"
          hide-error-row
          :model-value="sortBy"
          :options="sortOptions"
          @update:model-value="$emit('update:sortBy', $event)"
        >
          Sortierung
        </CarvisDropdown>
        <CarvisIcon v-if="showFilters">
          <Filter
            aria-label="show-filters"
            :size="24"
            @click="$emit('show-filters')"
          />
        </CarvisIcon>
      </div>
    </div>

    <span class="hidden lg:flex text-sm items-center space-x-2">
      <span>
        Sortiert nach
      </span>
      <CarvisDropdown
        id="sortBy"
        hide-error-row
        :model-value="sortBy"
        :options="sortOptions"
        @update:model-value="$emit('update:sortBy', $event)"
      />
    </span>
  </div>
</template>

<script setup lang="ts" generic="T">
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'
import Filter from 'vue-material-design-icons/Filter.vue'

defineProps<{
  limitOptions: { value: number | null; label: string }[]
  sortOptions: { value: SortBy<T> ; label: string }[]
  limit: number | null
  sortBy: SortBy<T>
  itemDescription: string
  showFilters?: boolean
}>()

const { lg } = useBreakpoints(breakpointsTailwind)

defineEmits(['update:limit', 'update:sortBy', 'show-filters'])
</script>
