<template>
  <Menu
    v-slot="{ open }"
    aria-label="flyout-menu"
    as="div"
    class="relative"
  >
    <slot
      name="menu-button"
      :open="open"
    >
      <MenuButton>
        <slot />
      </MenuButton>
    </slot>
    <FlyoutMenuTransition>
      <MenuItems
        class="flyout-menu-items right-0"
        :class="`${menuClass ?? ''} ${menuFixed ? 'fixed' : 'absolute'}`"
      >
        <slot name="menu-item">
          <MenuItem
            v-for="item in items"
            :key="item.label"
            v-slot="{ active, close }"
          >
            <div
              class="flyout-menu-item"
              :class="{ 'bg-gray-200': active }"
              @click.prevent="onClick(item, close)"
            >
              <div
                v-if="item.icon"
                class="w-fit"
              >
                <CarvisIcon class="!text-blue">
                  <component :is="item.icon" />
                </CarvisIcon>
              </div>
              <span>
                {{ item.label ?? item.value }}
              </span>
            </div>
          </MenuItem>
        </slot>
      </MenuItems>
    </FlyoutMenuTransition>
  </Menu>
</template>

<script setup lang="ts" generic="T">
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import type { Component } from 'vue'

import FlyoutMenuTransition from '@/components/flyout/FlyoutMenuTransition.vue'

defineProps<{
  items?: {handler: () => void, value: T, label?: string, icon?: Component }[]
  menuClass?: string
  menuFixed?: boolean
}>()

const onClick = (item: {handler: () => void}, close: () => void): void => {
  item.handler()
  close()
}

</script>
