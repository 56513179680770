<template>
  <LoadingImage
    ref="imageContainer"
    :bg-color="bgColor"
    :high-res-img-src="url"
    :image-error="imageError"
    :loading="loading"
    :low-res-img-src="lazyUrl ?? undefined"
    :mode="mode"
  />
</template>

<script setup lang="ts">
import { useElementVisibility } from '@vueuse/core'
import { ref, watch } from 'vue'
import { useTemplateRef } from 'vue'

import carImagesApi from '@/api/images/car-images-api'
import carDummyHighRes from '@/assets/car_dummy_highres.webp'
import carDummyLowRes from '@/assets/car_dummy_lowres.webp'
import LoadingImage from '@/components/images/LoadingImage.vue'
import { captureException } from '@/sentry'

const props = defineProps<{
  imageId?: string
  file?: File
  loading?: boolean
  size?: TransformOptions['size']
  bgColor?: string
  mode: 'square-keep-aspect' | 'square-cover' | 'keep-aspect'
}>()

const imageError = ref(false)
const lazyUrl = ref<null | string>(null)
const url = ref<null | string>(null)
const imageContainer = useTemplateRef('imageContainer')
const visible = useElementVisibility(imageContainer)

watch(visible, (isVisible) => isVisible && !url.value && loadImage())
watch(() => props.imageId, () => loadImage())
watch(() => props.file, (file) => {
  if (file) {
    lazyUrl.value = URL.createObjectURL(file)
    url.value = URL.createObjectURL(file)
  }
})

const loadImage = async (): Promise<void> => {
  if (props.file) {
    lazyUrl.value = URL.createObjectURL(props.file)
    url.value = URL.createObjectURL(props.file)
    return
  }
  imageError.value = false
  if (!props.imageId) {
    lazyUrl.value = carDummyLowRes
    url.value = carDummyHighRes
    return
  }
  lazyUrl.value = null
  url.value = null
  const previewResize = props.mode === 'keep-aspect' ? 'contain' : 'cover'
  carImagesApi.fetchImageUrl(props.imageId, { size: 32, quality: 20, resize: previewResize })
    .then(url => lazyUrl.value = url)
    .catch(captureException)
  const resize = props.mode === 'square-cover' ? 'cover' : 'contain'
  carImagesApi.fetchImageUrl(props.imageId, { size: props.size ?? 200, resize })
    .then(u => url.value = u)
    .catch(err => {
      imageError.value = true
      captureException(err)
    })
}
</script>
