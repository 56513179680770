<template>
  <BorderedLayout>
    <TableHeader
      :item-description="itemDescription"
      :limit="limit"
      :limit-options="limitOptions"
      :sort-by="sortBy"
      :sort-options="sortOptions"
      @update:limit="$emit('update:limit', $event)"
      @update:sort-by="$emit('update:sortBy', $event)"
    />
    <TableBody
      :items="items"
      :loading="loading"
    >
      <template
        v-if="slots['no-data']"
        #no-data
      >
        <slot name="no-data" />
      </template>
      <template
        v-else
        #no-data
      >
        Keine {{ itemDescription }} vorhanden.
      </template>
      <template #default="{ item }">
        <slot :item="item" />
      </template>
    </TableBody>
    <TablePagination
      :count="count"
      :item-description="itemDescription"
      :limit="limit"
      :loading="loading"
      :page="page"
      @update:page="$emit('update:page', $event)"
    />
  </BorderedLayout>
</template>

<script setup lang="ts" generic="T extends { id: string }">
import { useSlots } from 'vue'

import BorderedLayout from '@/components/layouts/BorderedLayout.vue'
import TableBody from '@/components/table/TableBody.vue'
import TableHeader from '@/components/table/TableHeader.vue'
import TablePagination from '@/components/table/TablePagination.vue'


defineProps<{
  items: T[] | null
  itemDescription: string
  loading: boolean
  limit: number | null
  sortBy: SortBy<T>
  limitOptions: { value: number | null; label: string }[]
  sortOptions: { value: SortBy<T>, label: string }[]
  count: number | null
  page: number
}>()

const slots = useSlots()

defineEmits(['update:limit', 'update:sortBy', 'update:page'])
</script>
