<template>
  <div
    aria-label="toggle-sidebar"
    class="flex items-center"
  >
    <CarvisBadge
      v-if="!md"
      :count="notificationStore.allNotifications"
    >
      <div
        class="bg-gray-800 h-10 w-10 flex justify-center items-center rounded-full text-gray-300 hover:text-gray-200 cursor-pointer hover:ring-orange hover:ring-2 Hover:ring-offset-2 hover:ring-offset-blue transition-transform sidebar-animation-duration"
        :class="{ 'rotate-180': expanded }"
        @click="$emit('update:expanded', !expanded)"
      >
        <Menu />
      </div>
    </CarvisBadge>
    <div
      v-else
      class="bg-gray-800 h-10 w-10 flex justify-center items-center rounded-full text-gray-300 hover:text-gray-200 cursor-pointer hover:ring-orange hover:ring-2 Hover:ring-offset-2 hover:ring-offset-blue transition-transform sidebar-animation-duration"
      :class="{ 'rotate-180': expanded }"
      @click="$emit('update:expanded', !expanded)"
    >
      <Menu />
    </div>
  </div>
</template>

<script setup lang="ts">
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'
import Menu from 'vue-material-design-icons/Menu.vue'

import { useNotificationsStore } from '@/stores/notifications-store'

defineProps<{
  expanded: boolean
}>()

const notificationStore = useNotificationsStore()
const { md } = useBreakpoints(breakpointsTailwind)

defineEmits(['update:expanded'])
</script>
