<template>
  <component
    v-bind="$attrs"
    :is="svg"
  />
</template>

<script setup lang="ts">
const props = defineProps<{
  small?: boolean
}>()
import Carvis from '@/assets/carvis.svg'
import CarvisSmall from '@/assets/carvis-small.svg'

const svg = props.small ? CarvisSmall : Carvis
</script>
