<template>
  <component
    :is="markRaw({...modalsStore.activeModal})"
    v-if="modalsStore.activeModal != null"
    :data="modalsStore.modalData"
  />
</template>

<script setup lang="ts">
import { markRaw } from 'vue'

import { useModalsStore } from '@/stores/modals-store'

const modalsStore = useModalsStore()
</script>
