import type { SeverityLevel } from '@sentry/vue'
import * as Sentry from '@sentry/vue'

export function captureInfo (message: string, extras?: Record<string, unknown>): string {
  return send((msg) => Sentry.captureMessage(msg), 'info', message, extras)
}

export function captureException (error: unknown, extras?: Record<string, unknown>): string {
  const { captureFunction, payload } = error instanceof Error
    ? { captureFunction: Sentry.captureException, payload: error }
    : { captureFunction: Sentry.captureMessage, payload: JSON.stringify(error) }
  return send((p) => captureFunction(p), 'error', payload, extras)
}

const send = <T>(
  sendFn: (payload: T) => string,
  severity: SeverityLevel,
  payload: T,
  extras?: Record<string, unknown>
): string => {
  if (!import.meta.env.VITE_PUBLIC_VERCEL_ENV) {
    if (Object.prototype.hasOwnProperty.call(payload, 'message')) {
      // eslint-disable-next-line no-console
      console.error('SENTRY: ', (payload as Error).message, payload ?? '', extras ?? '')
    } else {
      // eslint-disable-next-line no-console
      console.log('SENTRY: ', payload, extras ?? '')
    }
  }

  return Sentry.withScope((scope) => {
    if (extras) {
      Object.keys(extras).forEach(key => {
        const value = extras[key]
        scope.setExtra(key, value ? JSON.stringify(value) : undefined)
      })
    }
    scope.setLevel(severity)
    return sendFn(payload)
  })
}
