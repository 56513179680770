<template>
  <nav class="flex-1 flex flex-col bg-white border-r-2 border-orange p-2 shadow-lg shadow-orange overflow-y-auto">
    <div class="h-full">
      <SidebarItem
        v-for="item in userItems"
        :key="item.name"
        :expanded="expanded"
        :name="item.name"
        :notifications="item.notifications"
        :to="item.to"
        @update:expanded="$emit('update:expanded', $event)"
      >
        <template #icon>
          <component :is="item.icon" />
        </template>
      </SidebarItem>
    </div>
    <div v-if="isAdmin">
      <div class="w-auto border-gray-600/20 border-b mx-4" />

      <SidebarItem
        v-for="item in adminItems"
        :key="item.name"
        :expanded="expanded"
        :name="item.name"
        :notifications="item.notifications"
        :to="item.to"
        @update:expanded="$emit('update:expanded', $event)"
      >
        <template #icon>
          <component :is="item.icon" />
        </template>
      </SidebarItem>
      <div class="w-auto border-gray-600/20 border-b mx-4" />
    </div>
    <div>
      <SidebarItem
        :expanded="expanded"
        name="Hilfe"
        to="/feedback"
        @update:expanded="$emit('update:expanded', $event)"
      >
        <template #icon>
          <Lifebuoy />
        </template>
      </SidebarItem>
      <SidebarItem
        :expanded="expanded"
        name="Mein Profil"
        to="/my-profile"
        @update:expanded="$emit('update:expanded', $event)"
      >
        <template #icon>
          <AccountOutline />
        </template>
      </SidebarItem>
      <SidebarItem
        clickable
        :expanded="expanded"
        name="Ausloggen"
        @click="logout"
        @update:expanded="$emit('update:expanded', $event)"
      >
        <template #icon>
          <Logout />
        </template>
      </SidebarItem>
    </div>
    <div class="w-auto border-gray-600/20 border-b mx-4" />
    <SidebarItem
      :expanded="expanded"
      :name="name || email || ''"
      @update:expanded="$emit('update:expanded', $event)"
    >
      <template #icon>
        <ProfileAvatar
          aria-label="sidebar-avatar"
          :avatar-image-id="profilesStore.currentProfile?.avatar_image_id ?? null"
          class="w-6 h-6 flex-shrink-0"
          :profile-id="profilesStore.currentProfile?.id ?? null"
        />
      </template>
    </SidebarItem>
  </nav>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { computed } from 'vue'
import AccountGroupOutline from 'vue-material-design-icons/AccountGroupOutline.vue'
import AccountOutline from 'vue-material-design-icons/AccountOutline.vue'
import CarOutline from 'vue-material-design-icons/CarOutline.vue'
import GraphOutline from 'vue-material-design-icons/GraphOutline.vue'
import HomeOutline from 'vue-material-design-icons/HomeOutline.vue'
import InboxMultipleOutline from 'vue-material-design-icons/InboxMultipleOutline.vue'
import Lifebuoy from 'vue-material-design-icons/Lifebuoy.vue'
import Logout from 'vue-material-design-icons/Logout.vue'
import { useRouter } from 'vue-router'

import ProfileAvatar from '@/components/profile/ProfileAvatar.vue'
import SidebarItem from '@/components/sidebar/SidebarItem.vue'
import { useAuthStore } from '@/stores/auth-store'
import { useNotificationsStore } from '@/stores/notifications-store'
import { useProfilesStore } from '@/stores/profiles-store'

defineProps<{
  expanded: boolean
}>()

const authStore = useAuthStore()
const profilesStore = useProfilesStore()
const router = useRouter()
const notificationStore = useNotificationsStore()

const { isAdmin } = storeToRefs(authStore)
const name = computed(() => [profilesStore.currentProfile?.first_name, profilesStore.currentProfile?.last_name].filter(Boolean).join(' '))
const email = computed(() => profilesStore.currentProfile?.email)

const logout = async (): Promise<void> => {
  await authStore.logout()
  await router.push('/')
}

const userItems = computed(() => {
  return [
    { name: 'Dashboard', to: '/dashboard', icon: HomeOutline },
    { name: 'Fahrzeuge', to: '/cars', icon: CarOutline, notifications: notificationStore.newCarsNotifications },
    { name: 'Mein Netzwerk', to: '/my-network', icon: GraphOutline, notifications: notificationStore.myNetworkNotifications }
  ]
    .filter(({ to }) => {
      const requiresRole = router.getRoutes().find(route => route.path === to)?.meta.requiresRole
      if (!requiresRole) return true
      return authStore.hasRole(requiresRole)
    })
})

const adminItems = computed(() => {
  return [
    { name: 'Benutzerverwaltung', to: '/profiles-management', icon: AccountGroupOutline, notifications: notificationStore.newUsersNotifications },
    { name: 'Nutzerfeedback', to: '/user-feedback', icon: InboxMultipleOutline, notifications: notificationStore.feedbackMessagesNotifications }
  ]
    .filter(({ to }) => {
      const requiresRole = router.getRoutes().find(route => route.path === to)?.meta.requiresRole
      if (!requiresRole) return true
      return authStore.hasRole(requiresRole)
    })
})

defineEmits(['update:expanded'])
</script>
