<template>
  <div
    class="flex fixed flex-col flex-grow-0 sidebar-animation transition-width-override z-10"
    :class="{
      'sidebar-width': !expanded,
      'sidebar-width-expanded': expanded,
      '!w-0': sm && !expanded,
    }"
  >
    <SidebarContent
      v-if="!sm"
      :expanded="expanded"
    />

    <transition
      enter-active-class="sidebar-animation"
      enter-from-class="w-0 !px-0"
      enter-to-class="sidebar-width-expanded"
      leave-active-class="sidebar-animation"
      leave-from-class="sidebar-width-expanded"
      leave-to-class="w-0 !px-0"
    >
      <SidebarContent
        v-if="sm && expanded"
        class="overflow-hidden md:hidden"
        :expanded="true"
        @update:expanded="$emit('update:expanded', $event)"
      />
    </transition>
  </div>
</template>

<script setup lang="ts">
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'
import { computed, onMounted } from 'vue'
import { onUnmounted } from 'vue'

import SidebarContent from '@/components/sidebar/SidebarContent.vue'
import { useNotificationsStore } from '@/stores/notifications-store'
import { useRealtimeStore } from '@/stores/realtime-store'
import { useToastsStore } from '@/stores/toasts-store'

defineProps<{
  expanded: boolean
}>()

const { md } = useBreakpoints(breakpointsTailwind)
const sm = computed(() => !md.value)
const realtimeStore = useRealtimeStore()
const notificationStore = useNotificationsStore()
const toastStore = useToastsStore()

onMounted(() => {
  realtimeStore.subscribe('TheSidebar', [
    'connections',
    'connection_requests',
    'profiles',
    'feedback_messages',
    'connections_read',
    'profiles_read',
    'feedback_messages_read',
    'cars',
    'cars_read'
  ], fetchNotificationCounts)
  fetchNotificationCounts()
})

onUnmounted(() => realtimeStore.unsubscribe('TheSidebar'))

const fetchNotificationCounts = async (): Promise<void> => {
  try {
    await notificationStore.fetchNotificationCounts()
  } catch (e: unknown) {
    toastStore.error('Fehler beim Laden der Benachrichtigungen', 'Beim Laden der Benachrichtigungen ist ein Fehler aufgetreten. Bitte versuche es erneut.', e)
  }
}

defineEmits(['update:expanded'])
</script>

<style scoped>
.transition-width-override {
  transition-property: width !important;
}
</style>
