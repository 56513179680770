<template>
  <div
    class="relative inline-flex items-center px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300"
    :class="{
      'cursor-not-allowed opacity-40': disabled,
      'cursor-pointer hover:bg-gray-50': !disabled,
      'rounded-l-md': back,
      'rounded-r-md': next,
    }"
    @click="onClick"
  >
    <svg
      aria-hidden="true"
      class="h-5 w-5"
      fill="currentColor"
      viewBox="0 0 20 20"
    >
      <path
        v-if="back"
        clip-rule="evenodd"
        d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
        fill-rule="evenodd"
      />
      <path
        v-if="next"
        clip-rule="evenodd"
        d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
        fill-rule="evenodd"
      />
    </svg>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  back?: boolean
  next?: boolean
  disabled?: boolean
}>()

const emit = defineEmits(['click'])

const onClick = (): void => {
  if (!props.disabled) {
    emit('click')
  }
}
</script>
