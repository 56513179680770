import { defineStore } from 'pinia'
import type { Component } from 'vue'

export const useModalsStore = defineStore('modals', {
  state: () => ({
    activeModal: null as Component | null,
    modalData: null as unknown | null
  }),
  actions: {
    openModal(activeModal: Component, modalData?: unknown): void {
      this.activeModal = activeModal
      this.modalData = modalData ?? null
    },
    closeModal(): void {
      this.activeModal = null
      this.modalData = null
    }
  }
})
