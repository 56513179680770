export default class Semaphore {
  private queue: { priority: number, resolve: () => void }[] = []
  private currentCount = 0
  private limit: number

  constructor(limit: number) {
    this.limit = limit
  }

  private acquire(priority: number): Promise<void> {
    if (this.currentCount < this.limit) {
      this.currentCount += 1
      return Promise.resolve()
    }
    return new Promise((resolve) => {
      this.queue.push({ priority, resolve })
    })
  }

  private release(): void {
    if (this.queue.length) {
      this.queue.sort((a, b) => a.priority - b.priority)
      const { resolve } = this.queue.shift()!
      resolve()
    } else {
      this.currentCount -= 1
    }
  }

  public async use<T>(fn: () => Promise<T>, priority = 0): Promise<T> {
    await this.acquire(priority)
    try {
      return await fn()
    } finally {
      this.release()
    }
  }
}
