<template>
  <div class="has-tooltip">
    <slot />
    <span
      class="tooltip opacity-90 md:block rounded-lg shadow-lg py-1 bg-gray-100 text-blue fixed transition-opacity duration-300 px-2 ring-1 ring-blue-dark text-center z-10 -translate-x-1/2 text-nowrap"
      :class="margin"
    >
      <slot name="tooltip-text" />
    </span>
  </div>
</template>


<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps<{
  position?: 'top' | 'bottom' | 'left' | 'right'
}>()

const margin = computed(() => {
  switch (props.position) {
  case 'top':
    return '-mt-[6.7rem]'
  case 'bottom':
    return 'mt-2'
  case 'left':
    return 'mr-2'
  case 'right':
    return 'ml-2'
  default:
    return ''
  }
})
</script>

<style scoped>
.tooltip {
  @apply hidden;
}

.has-tooltip:hover .tooltip {
  @apply md:!block;
}
</style>
