<template>
  <div class="w-full bg-gray-200 rounded-full h-2.5 overflow-hidden">
    <div
      class="bg-blue-light h-2.5 rounded-full"
      :class="{
        'animate-progress origin-left-right': indeterminate,
        'transition-all duration-1000 ease-in-out': !indeterminate
      }"
      :style="{ 'width': width }"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps<{
  progress?: number
}>()

const indeterminate = computed(() => props.progress === undefined)
const width = computed(() => {
  if (indeterminate.value) return '100%'
  return `${props.progress}%`
})
</script>
