<template>
  <div
    aria-label="speed-dial"
    class="flex flex-col items-end fixed bottom-10 right-4 md:right-6 md:bottom-6 lg:bottom-8 lg:right-8 space-y-2 z-10"
  >
    <ul class="space-y-2">
      <li
        v-for="(item, i) in items"
        :key="item.label"
      >
        <transition
          enter-active-class="transition-all duration-500"
          :enter-from-class="`opacity-0 ${translateY(i)}`"
          enter-to-class="opacity-100 translate-y-0"
          leave-active-class="transition-all duration-500"
          leave-from-class="opacity-100 translate-y-0"
          :leave-to-class="`opacity-0 ${translateY(i)}`"
        >
          <RouterLink
            v-if="active"
            :aria-label="item.label"
            class="flex space-x-2 items-center cursor-pointer justify-end text-gray-600 group"
            :to="item.to"

            @click="$emit('update:active', false)"
          >
            <div class="ring-1 ring-gray-400 bg-white p-1 rounded-lg text-sm duration-500 group-hover:bg-gray-50 group-hover:ring-orange">
              {{ item.label }}
            </div>
            <div class="flex items-center justify-center h-16 md:h-12 w-16 md:w-12 bg-white rounded-full ring-1 ring-gray-400 group-hover:bg-gray-50 group-hover:ring-orange">
              <component
                :is="item.icon"
                div
                :size="36"
              />
            </div>
          </RouterLink>
        </transition>
      </li>
    </ul>
    <div
      aria-label="speed-dial-trigger"
      class="relative bg-blue rounded-full aspect-square h-16 md:h-12 w-16 md:w-12 flex items-center justify-center shadow-2xl ring-1 ring-orange cursor-pointer"
    >
      <Plus
        class="text-gray-300 transition-transform duration-500"
        :class="{ 'rotate-45': active }"
        :size="48"
        @click="$emit('update:active', !active)"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'
import { computed } from 'vue'
import CarOutline from 'vue-material-design-icons/CarOutline.vue'
import Lifebuoy from 'vue-material-design-icons/Lifebuoy.vue'
import Plus from 'vue-material-design-icons/Plus.vue'
import { useRouter } from 'vue-router'

import { useAuthStore } from '@/stores/auth-store'

defineProps<{
  active: boolean
}>()

const { md } = useBreakpoints(breakpointsTailwind)
const router = useRouter()
const authStore = useAuthStore()

// make sure to update tailwind.config.js safelist when adding new elements
const items = computed(() => {
  return [
    { label: 'Ich brauche Hilfe!', icon: Lifebuoy, to: '/feedback' },
    { label: 'Neues Fahrzeug', icon: CarOutline, to: '/cars/new' }
  ]
    .filter(({ to }) => {
      const requiresRole = router.getRoutes().find(route => route.path === to)?.meta.requiresRole
      if (!requiresRole) return true
      return authStore.hasRole(requiresRole)
    })
})

// make sure to update tailwind.config.js safelist
const iconSize = computed(() => md.value ? 3.5 : 4.5)

const translateY = (i: number): string => `translate-y-[${iconSize.value * items.value.length - (iconSize.value * i)}rem]`
defineEmits(['update:active'])
</script>
