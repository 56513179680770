<template>
  <FlyoutMenu
    :items="items"
    :menu-class="`${!hideErrorRow ? 'top-14': 0}`"
  >
    <template #menu-button="{ open }">
      <CarvisInput
        v-bind="$attrs"
        :id="id"
        class="w-full"
        :class="{ 'cursor-not-allowed': disabled }"
        :disabled="disabled"
        :hide-error-row="hideErrorRow"
        :input="MenuButton"
      >
        <div
          v-if="loading"
          class="flex items-center justify-center w-full"
        >
          <CarvisSpinner class="h-5" />
        </div>
        <div
          v-else
          class="inline-flex justify-between w-full items-center"
        >
          <span>
            <slot>
              {{ labelOfValue }}
            </slot>
          </span>
          <ToggleDropdownIcon :open="open" />
        </div>
      </CarvisInput>
    </template>
  </FlyoutMenu>
</template>

<script setup lang="ts" generic="T">
import { MenuButton } from '@headlessui/vue'
import { computed } from 'vue'

import ToggleDropdownIcon from '@/components/dropdown/ToggleDropdownIcon.vue'
import FlyoutMenu from '@/components/flyout/FlyoutMenu.vue'

const props = defineProps<{
  modelValue: T
  options: { value: T, label?: string }[]
  loading?: boolean
  id: string
  disabled?: boolean
  hideErrorRow?: boolean
}>()

const emit = defineEmits(['update:modelValue'])

const items = computed(() =>
  props.options.map(option => ({
    ...option,
    handler: () => emit('update:modelValue', option.value)
  }))
)

const disabled = computed(() => props.loading || props.options.length <= 1)

const labelOfValue = computed(() => {
  const v = props.options.find(option => JSON.stringify(option.value) == JSON.stringify(props.modelValue))
  return v?.label ?? v?.value
})
</script>
