<template>
  <CarvisInput
    input="div"
    :label="label"
  >
    <Combobox
      v-slot="{ open }"
      as="div"
      class="relative"
      :class="{ 'opacity-30 cursor-not-allowed': disabled }"
      :disabled="disabled"
      :model-value="modelValue"
      @change="onChange"
      @update:model-value="$emit('update:modelValue', $event.value)"
    >
      <ComboboxButton class="flex justify-between w-full items-center">
        <ComboboxInput
          v-bind="$attrs"
          :aria-label="label"
          class="nested-input-field"
          :loading="loading"
          :value="modelValue"
          @blur="$emit('blur')"
          @change="onChange"
        />
        <ToggleDropdownIcon :open="open" />
      </ComboboxButton>
      <FlyoutMenuTransition>
        <ComboboxOptions
          v-if="allItems.length > 0"
          class="absolute flyout-menu-items mt-1.5 max-h-48 overflow-y-auto"
          :class="{ 'cursor-not-allowed': items === null }"
        >
          <div
            v-if="items === null"
            class="w-full flex justify-center p-2"
          >
            <CarvisSpinner class="w-5" />
          </div>
          <ComboboxOption
            v-for="item in allItems"
            v-else
            :key="item.value"
            v-slot="{ active }"
            :value="item"
          >
            <div
              class="flyout-menu-item flex-col !items-start"
              :class="{ 'bg-gray-200': active }"
            >
              <div :class="{ 'font-bold text-blue-dark': modelValue === item.value }">
                {{ item.value }}
              </div>
              <span class="text-xs font-light !m-0">
                {{ item.description }}
              </span>
            </div>
          </ComboboxOption>
        </ComboboxOptions>
      </FlyoutMenuTransition>
    </Combobox>
  </CarvisInput>
</template>

<script setup lang="ts">
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from '@headlessui/vue'
import { computed } from 'vue'

import ToggleDropdownIcon from '@/components/dropdown/ToggleDropdownIcon.vue'
import FlyoutMenuTransition from '@/components/flyout/FlyoutMenuTransition.vue'

const props = defineProps<{
  items: { value: string, description?: string }[] | null
  modelValue?: string | null
  disabled?: boolean
  loading?: boolean
  label: string
}>()

const allItems = computed(() => {
  const items = new Set(props.items ?? [])
  const notInSet = !props.items?.map(a => a.value).find(v => v === props.modelValue)
  if (props.modelValue && notInSet) items.add({ value: props.modelValue })
  return [...items].sort((a, b) => {
    if (a.value === props.modelValue) return -1
    if (b.value === props.modelValue) return 1
    return a.value.localeCompare(b.value)
  })
})

const onChange = (event: Event): void => {
  const target = event.target as HTMLInputElement
  const value = target.value
  emit('update:modelValue', value)
}

const emit = defineEmits(['update:modelValue', 'blur'])
</script>
