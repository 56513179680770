<template>
  <div
    class="relative px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300"
    :class="{
      'bg-blue-light text-white': active,
      'text-gray-900 ': !active,
      'cursor-pointer': page !== undefined,
      'hover:bg-gray-50': page !== undefined && !active,
    }"
    @click="onClick"
  >
    <template v-if="slots.default">
      <slot />
    </template>
    <template v-else-if="page !== undefined">
      {{ page }}
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed, useSlots } from 'vue'

const props = defineProps<{
  page?: number
  currentPage?: number
}>()

const emit = defineEmits(['click'])

const slots = useSlots()

const active = computed(() => props.page !== undefined && props.currentPage !== undefined && props.page === props.currentPage)

const onClick = (): void => {
  if (props.page !== undefined) {
    emit('click', props.page)
  }
}
</script>
