<template>
  <div class="bg-gray-400 border border-gray-400 border-opacity-40 h-full rounded-md hatched bg-opacity-50" />
</template>

<script setup lang="ts">

</script>

<style scoped>
.hatched {
  background:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAAXNSR0IArs4c6QAAAEBJREFUKFNjZCACzF68/j8jIXUgRamxgYx4FcIUgQzDqRBZEU6F6IqwKsSmCEMhLkUoCvEpgiskpAiskBhFIIUAXDkm9/LYZBQAAAAASUVORK5CYII=)
}
</style>
