<template>
  <component
    :is="to ? 'router-link' : 'a'"
    class="font-semibold text-blue-light hover:text-orange cursor-pointer"
    v-bind="$attrs"
    :target="target"
    :to="to"
  >
    <slot v-if="!isExternal" />
    <span
      v-else
      class="whitespace-nowrap"
    >
      <span class="align-top inline-block">
        <slot />
      </span>
      <div
        v-if="isExternal"
        class="align-text-top inline-block ml-0.5"
      >
        <OpenInNew :size="16" />
      </div>
    </span>
  </component>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import OpenInNew from 'vue-material-design-icons/OpenInNew.vue'
import type { RouteLocationRaw } from 'vue-router'

const props = defineProps<{
  to?: RouteLocationRaw
  target?: string
}>()

const isExternal = computed(() => props.target === '_blank')

</script>
