import { formatDistance as formatDistanceFns } from 'date-fns'
import { de } from 'date-fns/locale'

export const formatDistance = (date: string, addSuffix = true): string =>
  formatDistanceFns(new Date(date), new Date(), { addSuffix, locale: de })

export const resizeImage = (file: File, maxHeight: number): Promise<Blob> => {
  const reader = new FileReader()
  const contentType = file.type
  reader.readAsDataURL(file)
  return new Promise((resolve, reject) => {
    reader.onload = (e: ProgressEvent<FileReader>) => {
      const img = new Image()
      if (e.target === null) return reject(new Error('Failed to resize image'))
      if (typeof e.target.result !== 'string') return reject(new Error('Failed to resize image'))
      img.src = e.target.result

      img.onload = async () => {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')

        // Calculate the new dimensions while maintaining the aspect ratio
        let newWidth = img.width
        let newHeight = img.height
        if (img.height > maxHeight) {
          const ratio = maxHeight / img.height
          newHeight = maxHeight
          newWidth = img.width * ratio
        }

        // Set canvas dimensions
        canvas.width = newWidth
        canvas.height = newHeight

        // Draw the image on the canvas with the new dimensions
        if (!ctx) return reject(new Error('Failed to resize image'))
        ctx.drawImage(img, 0, 0, newWidth, newHeight)

        // Convert the canvas content to a a blob
        canvas.toBlob(blob => {
          if (!blob) return reject(new Error('Failed to resize image'))
          resolve(blob)
        }, contentType, 0.8)
      }
    }
  })
}

export const rotateImage = (blob: Blob, degree: -90 | 90): Promise<Blob> => {
  const reader = new FileReader()
  const contentType = blob.type
  reader.readAsDataURL(blob)
  return new Promise((resolve, reject) => {
    reader.onload = (e: ProgressEvent<FileReader>) => {
      const img = new Image()
      if (e.target === null) return reject(new Error('Failed to rotate image'))
      if (typeof e.target.result !== 'string') return reject(new Error('Failed to rotate image'))
      img.src = e.target.result
      img.onload = async () => {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')

        // Set canvas dimensions to accommodate rotation
        if (degree === 90 || degree === -90) {
          canvas.width = img.height
          canvas.height = img.width
        } else {
          canvas.width = img.width
          canvas.height = img.height
        }

        // Rotate the image on the canvas
        if (!ctx) return reject(new Error('Failed to rotate image'))
        ctx.translate(canvas.width / 2, canvas.height / 2)
        ctx.rotate(degree * (Math.PI / 180))
        ctx.drawImage(img, -img.width / 2, -img.height / 2)

        // Convert the canvas content to a blob
        canvas.toBlob(blob => {
          if (!blob) return reject(new Error('Failed to rotate image'))
          resolve(blob)
        }, contentType, 1.0)
      }
    }
  })
}

interface MemoizeCache<Key, Value> {
  /**
   * Get value for key
   */
  get: (key: Key) => Value | undefined
  /**
   * Set value for key
   */
  set: (key: Key, value: Value) => void
  /**
   * Return flag if key exists
   */
  has: (key: Key) => boolean
  /**
   * Delete value for key
   */
  delete: (key: Key) => void
  /**
   * Clear cache
   */
  clear: () => void
}


export class ExpiringCache<Key, Value> implements MemoizeCache<Key, Value> {
  private cache: Map<Key, { value: Value, date: Date }>
  private expirationInMs: number

  constructor(expirationInMs: number) {
    this.cache = new Map()
    this.expirationInMs = expirationInMs
  }

  get(key: Key): Value | undefined {
    if (!this.has(key)) return undefined
    return this.cache.get(key)?.value
  }

  set(key: Key, value: Value): void {
    this.cache.set(key, { value, date: new Date() })
  }

  has(key: Key): boolean {
    const v = this.cache.get(key)
    if (!v) return false
    const expired = new Date().getTime() - v.date.getTime() > this.expirationInMs
    if (expired) {
      this.cache.delete(key)
      return false
    }
    return true
  }

  delete(key: Key): void {
    this.cache.delete(key)
  }

  clear(): void {
    this.cache.clear()
  }
}
