<template>
  <div
    aria-label="profile-avatar"
    class="relative flex aspect-square flex-grow"
  >
    <CarvisSpinner
      v-show="loading"
      class="absolute z-10"
    />
    <img
      class="rounded-full w-full h-full object-cover"
      :src="avatarImgSrc ?? `https://api.multiavatar.com/${profileId}.svg`"
      @load="loading = false"
    >
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from 'vue'

import avatarImagesApi from '@/api/images/avatar-images-api'
import { useToastsStore } from '@/stores/toasts-store'

const props = defineProps<{
  profileId: string | null
  avatarImageId: string | null
  imageIdOverride?: string
}>()

const loading = ref(true)
const avatarImgSrc = ref<string | null>(null)
const toastsStore = useToastsStore()

const loadAvatarFromImageId = async (imageId: string): Promise<void> => {
  try {
    const imageUrl = await avatarImagesApi.fetchImageUrl(imageId, { size: 200, resize: 'contain' })
    avatarImgSrc.value = imageUrl
  } catch (error) {
    toastsStore.error('Fehler beim Laden eines Profilfotos', 'Beim Laden eines Profilfotos ist ein Fehler aufgetreten. Bitte versuche es erneut.', error as Error)
    loading.value = false
  }
}

const loadAvatar = (): void => {
  if (props.imageIdOverride) {
    loadAvatarFromImageId(props.imageIdOverride)
  } else if (props.avatarImageId) {
    loadAvatarFromImageId(props.avatarImageId)
  } else if (props.avatarImageId === null) {
    avatarImgSrc.value = null
  }
}

watch(() => props.avatarImageId, loadAvatar)
watch(() => props.imageIdOverride, loadAvatar)
watch(() => props.profileId, loadAvatar)
onMounted(() => loadAvatar())
</script>
