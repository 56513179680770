<template>
  <div
    class="w-6 h-6 flex items-center justify-center"
    :class="{
      'opacity-50 cursor-not-allowed': disabled,
      'cursor-pointer': !disabled,
      'cursor-wait': loading,
      'hover:text-orange': !disabled && !loading
    }"
    @click="onClick"
  >
    <CarvisSpinner v-if="loading" />
    <slot v-else />
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  disabled?: boolean
  loading?: boolean
}>()

const emit = defineEmits(['click'])

const onClick = (): void => {
  if (props.disabled || props.loading) return
  emit('click')
}
</script>
