<!-- eslint-disable vue/no-v-html -->
<template>
  <BorderedLayout
    class="!p-0 shadow-xl !m-4 overflow-hidden"
    role="alert"
  >
    <ProgressBar
      class="h-1"
      :class="`bg-${toast.type}`"
      :duration="toast.timeout"
      :start="toast.start"
    />
    <div class="p-2">
      <div class="flex items-center w-full mb-2">
        <component
          :is="icon"
          class="mr-2"
          :class="`text-${toast.type}`"
        />
        <div
          aria-label="toast-title"
          class="font-semibold w-full text-gray-800"
        >
          {{ toast.title }}
        </div>
        <CarvisIcon
          aria-label="close-toast"
          @click="toast.dismiss()"
        >
          <Close />
        </CarvisIcon>
      </div>
      <div
        v-if="toast.message"
        aria-label="toast-message"
        class="flex space-x-2 text-gray-600"
      >
        <div class="w-7" />
        <div
          class="w-full whitespace-normal overflow-hidden text-sm font-light text-justify"
          v-html="toast.message"
        />
        <div class="w-6" />
      </div>
      <div
        v-if="toast.action"
        class="flex space-x-2 mt-2 mb-1"
      >
        <div class="w-7" />
        <div class="w-full">
          <div
            aria-label="toast-action"
            class="font-medium text-sm cursor-pointer hover:text-orange text-gray-800"
            @click="toast.action && toast.action.handler()"
          >
            {{ toast.action.text }}
          </div>
          <div class="w-6" />
        </div>
      </div>
    </div>
  </BorderedLayout>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import Check from 'vue-material-design-icons/Check.vue'
import Close from 'vue-material-design-icons/Close.vue'
import Exclamation from 'vue-material-design-icons/Exclamation.vue'
import InformationOutline from 'vue-material-design-icons/InformationOutline.vue'

import BorderedLayout from '@/components/layouts/BorderedLayout.vue'
import ProgressBar from '@/components/toast/ProgressBar.vue'

const props = defineProps<{
 toast: Toast
}>()

const icon = computed(() => {
  switch (props.toast.type) {
  case 'success':
    return Check
  case 'error':
    return Exclamation
  case 'info':
    return InformationOutline
  default:
    return null
  }
})
</script>
