import * as Sentry from '@sentry/vue'
import { supabaseIntegration } from '@supabase/sentry-js-integration'
import { SupabaseClient } from '@supabase/supabase-js'
import type { Plugin } from 'vue'

import router from '@/router'

const SentryPlugin: Plugin = {
  install(app) {

    if (!import.meta.env.VITE_PUBLIC_VERCEL_ENV) {
      // eslint-disable-next-line no-console
      console.log('SentryPlugin: Sentry is disabled in development mode')
      return
    }

    Sentry.init({
      app,
      dsn: 'https://5fd531ec877e9549cd9f681dda79e06c@o4506355748700160.ingest.sentry.io/4506355749617664',
      environment: import.meta.env.VITE_PUBLIC_VERCEL_ENV,
      integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration(),
        supabaseIntegration(SupabaseClient, Sentry, {
          tracing: true,
          breadcrumbs: true,
          errors: true
        })
      ],
      // Performance Monitoring
      tracesSampleRate: import.meta.env.VITE_PUBLIC_VERCEL_ENV === 'production' ? 0.1 : 1.0,
      // Session Replay
      replaysSessionSampleRate: 0.0,
      replaysOnErrorSampleRate: 1.0,
    })
  }
}

export default SentryPlugin
