<template>
  <div class="flex">
    <Switch
      class="relative inline-flex items-center h-6 w-12 shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75"
      :class="{
        'cursor-pointer': !loading && !disabled,
        'cursor-not-allowed': loading || disabled,
        'opacity-50': disabled,
        'bg-blue-light': modelValue,
        'bg-gray-200': !modelValue,
      }"
      :model-value="modelValue"
      @update:model-value="onInput"
    >
      <div
        class="bg-white inline-block h-5 w-5 transform rounded-full shadow-lg ring-inset ring-0 transition duration-200 ease-in-out border-gray-400 border"
        :class="{
          'hover:border-blue-light hover:border-2': !disabled && !loading,
          'translate-x-6 border-white': modelValue,
          'translate-x-0 border-gray-400': !modelValue
        }"
      >
        <CarvisSpinner v-if="loading" />
      </div>
    </Switch>
    <CarvisText class="mx-2 flex items-center text-blue-dark">
      <slot />
    </CarvisText>
  </div>
</template>

<script setup lang="ts">
import { Switch } from '@headlessui/vue'

const props = defineProps<{
  modelValue: boolean
  loading?: boolean
  disabled?: boolean
}>()

const emit = defineEmits(['update:modelValue'])

const onInput = (value: boolean): void => {
  if (props.loading || props.disabled) {
    return
  }

  emit('update:modelValue', value)
}
</script>
