<template>
  <header class="navigation-bar flex fixed top-0 w-full z-10">
    <div class="pl-4 absolute left-0 top-1/2 -translate-y-1/2">
      <SidebarToggle
        v-if="showSidebar"
        :expanded="sidebarExpanded"
        @update:expanded="$emit('update:sidebar-expanded', $event)"
      />
    </div>
    <CarvisLogo class="h-8 w-18 text-gray-300 fixed left-1/2 -translate-x-1/2 top-4" />
    <slot />
    <div class="wide-page-max-width w-full outer-page-padding-x flex items-center justify-end">
      <NavigationItem
        v-if="!isLoggedIn"
        :icon="Login"
        to="/login"
      >
        Log in
      </NavigationItem>
    </div>
  </header>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import Login from 'vue-material-design-icons/Login.vue'

import CarvisLogo from '@/components/navigation/CarvisLogo.vue'
import NavigationItem from '@/components/navigation/NavigationItem.vue'
import SidebarToggle from '@/components/sidebar/SidebarToggle.vue'
import { useAuthStore } from '@/stores/auth-store'

defineProps<{
  sidebarExpanded: boolean
  showSidebar: boolean
}>()

defineEmits(['update:sidebar-expanded'])

const authStore = useAuthStore()
const isLoggedIn = computed(() => authStore.isLoggedIn)
</script>
