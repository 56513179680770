<template>
  <div class="flex w-full">
    <RouterLink
      v-for="{ label, icon, badge, to, loading } in tabs"
      :key="label"
      aria-label="tab"
      class="w-full cursor-pointer group"
      :to="to"
    >
      <div class="h-full w-full flex items-center justify-center flex-col md:flex-row space-x-0 md:space-x-3 text-gray-700 hover:text-gray-800 px-1.5 pt-1.5 md:pb-1.5 hover:bg-gray-50 rounded-lg">
        <div class="flex space-x-2 items-center justify-center">
          <component
            :is="icon"
            :class="{ 'text-orange': route.path === to }"
          />
          <CarvisBadge
            v-if="!md"
            :count="badge"
            :loading="loading"
          />
        </div>
        <span class="text-sm font-medium text-center md:text-left">{{ label }}</span>
        <CarvisBadge
          v-if="md"
          :count="badge"
          :loading="loading"
        />
      </div>
      <div
        class="w-full h-1 border-b border-gray-200 group-hover:border-b-2 transition-colors"
        :class="{ 'border-orange border-b-2': route.path === to }"
      />
    </RouterLink>
  </div>
</template>

<script setup lang="ts" generic="T">
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'
import type { Component } from 'vue'
import { useRoute } from 'vue-router'

defineProps<{
  tabs: {
    label: string
    icon: Component
    to: string
    badge?: number
    loading?: boolean
  }[]
}>()

const { md } = useBreakpoints(breakpointsTailwind)

const route = useRoute()
</script>
