<template>
  <div :style="`width: ${width}%; transition: width ${duration}ms linear;`" />
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'

const props = defineProps<{
  duration: number
  start: Date
}>()

const now = ref(Date.now())
const duration = ref(props.duration)
const interval = ref<NodeJS.Timeout | null>(null)

onMounted(() => {
  const diff = Date.now() - props.start.getTime()
  duration.value = props.duration - diff
  interval.value = setInterval(() => {
    now.value = Date.now()
  }, 10)
})

const width = computed(() => {
  if (now.value < props.start.getTime()) {
    return 0
  } else {
    if (interval.value) clearInterval(interval.value)
    return 100
  }
})
</script>
