<template>
  <div
    id="main"
    class="h-[calc(100dvh)] flex overflow-x-hidden"
  >
    <TheNavigationBar
      ref="navbar"
      v-model:sidebar-expanded="sidebarExpanded"
      class="h-16"
      :show-sidebar="isLoggedIn"
    >
      <div
        class="sidebar-animation"
        :class="navbarOffsetLeft"
      />
    </TheNavigationBar>
    <TheSidebar
      v-if="isLoggedIn"
      ref="sidebar"
      v-model:expanded="sidebarExpanded"
      class="top-16 h-[calc(100vh-4rem)]"
    />
    <div
      class="sidebar-animation !transition-all !duration-0 md:!duration-500 mt-16 w-full"
      :class="{
        'sidebar-width-padding ': isLoggedIn && !sidebarExpanded && md,
        'sidebar-width-expanded-padding ': isLoggedIn && sidebarExpanded && md,
      }"
    >
      <FullHeightPage>
        <BreadcrumbsBar />
        <RouterView />
      </FullHeightPage>
    </div>
    <ToastsContainer :toasts="toastsStore.toasts" />
    <TheModalHome />
    <TheSpeedDial
      v-if="isLoggedIn"
      ref="speedDial"
      v-model:active="speedDialActive"
    />
  </div>
</template>

<script setup lang="ts">
import { breakpointsTailwind, type MaybeElement, type MaybeElementRef, onClickOutside, useBreakpoints } from '@vueuse/core'
import { storeToRefs } from 'pinia'
import { onUnmounted, ref } from 'vue'
import { computed } from 'vue'
import { watchEffect } from 'vue'
import { useTemplateRef } from 'vue'

import BreadcrumbsBar from '@/components/breadcrumbs/BreadcrumbsBar.vue'
import TheModalHome from '@/components/modal/TheModalHome.vue'
import TheNavigationBar from '@/components/navigation/TheNavigationBar.vue'
import FullHeightPage from '@/components/pages/FullHeightPage.vue'
import TheSidebar from '@/components/sidebar/TheSidebar.vue'
import TheSpeedDial from '@/components/speed-dial/TheSpeedDial.vue'
import ToastsContainer from '@/components/toast/ToastsContainer.vue'
import { useAuthStore } from '@/stores/auth-store'
import { useRealtimeStore } from '@/stores/realtime-store'
import { useToastsStore } from '@/stores/toasts-store'


const { md, lg } = useBreakpoints(breakpointsTailwind)
const toastsStore = useToastsStore()
const callbacksStore = useRealtimeStore()
const sidebarExpanded = ref(lg.value)
const authStore = useAuthStore()
const { isLoggedIn } = storeToRefs(authStore)
const sidebar = useTemplateRef('sidebar')
const navbar = useTemplateRef('navbar')
const speedDial = useTemplateRef('speedDial')
const speedDialActive = ref(false)

const sm = computed(() => !md.value)
watchEffect(() => {
  if (md.value && !lg.value) sidebarExpanded.value = false
  else if (lg.value) sidebarExpanded.value = true
})

onUnmounted(callbacksStore.onUnmounted)

onClickOutside(speedDial as MaybeElementRef<MaybeElement>, () => speedDialActive.value = false)
onClickOutside(sidebar as MaybeElementRef<MaybeElement>, () => {
  if (!md.value && sidebarExpanded.value) {
    sidebarExpanded.value = false
  }
}, { ignore: [navbar as MaybeElementRef<MaybeElement>] })

const navbarOffsetLeft = computed(() => {
  if (!isLoggedIn.value) {
    return 'w-0'
  } else if (sidebarExpanded.value) {
    return 'sidebar-width-expanded'
  } else {
    return sm.value ? 'w-0 !duration-0' : 'sidebar-width'
  }
})
</script>
