<template>
  <div
    aria-label="chip"
    class="bg-gray-100 text-gray-600 w-fit items-center whitespace-nowrap rounded-lg p-1 px-1.5 text-xs flex space-x-1 shadow-md font-medium ring-1 ring-gray-400"
  >
    <div>
      <slot name="icon" />
    </div>
    <span class="truncate">
      <slot />
    </span>
  </div>
</template>
