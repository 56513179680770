<template>
  <component
    :is="to ? 'RouterLink' : 'span'"
    :aria-label="name"
    :to="to"
  >
    <li
      class="my-1 px-4 py-2 rounded-md transition-colors text-gray-600 list-none flex items-center space-x-4"
      :class="{
        'bg-gray-300': active,
        'cursor-pointer hover:bg-gray-200 hover:text-gray-700': clickable || to,
      }"
      @click="(props.clickable || props.to) && $emit('update:expanded', false)"
    >
      <CarvisBadge :count="notifications">
        <slot name="icon" />
      </CarvisBadge>
      <transition
        enter-active-class="transition-opacity sidebar-animation-duration"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="transition-opacity sidebar-animation-duration"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <span
          v-if="expanded"
          class="truncate"
          :class="{
            'font-medium': !active,
            'font-semibold': active,
          }"
        >
          {{ name }}
        </span>
      </transition>
    </li>
  </component>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'

const props = defineProps<{
  to?: string,
  expanded: boolean
  clickable?: boolean
  name: string
  notifications?: number
}>()

const route = useRoute()
const active = computed(() => props.to && route.path.includes(props.to))

defineEmits(['update:expanded'])
</script>
