import * as Sentry from '@sentry/vue'
import type { User } from '@supabase/supabase-js'
import { defineStore } from 'pinia'
import { computed, ref, watchEffect } from 'vue'

import supabase from '@/supabase'

export const useAuthStore = defineStore('auth', () => {
  const user = ref<User | null>(null)

  const isAdmin = computed(() => userRoles.value?.includes('admin'))
  const isLoggedIn = computed(() => userId.value !== null && userRoles.value !== null)
  const userId = computed(() => user.value?.id ?? null)
  const email = computed(() => user.value?.email ?? null)
  const userRoles = computed<Role[]>(() => user.value?.app_metadata?.user_roles ?? [])

  const hasRole = (requiredRole?: Role): boolean => {
    if (isAdmin.value) return true
    if (!requiredRole) return true
    if (!userRoles.value?.length) return false
    return userRoles.value.some(role => role === requiredRole)
  }

  watchEffect(() => {
    if (!userId.value && !email.value) {
      Sentry.setUser(null)
    } else {
      Sentry.setUser({
        id: userId.value ?? undefined,
        email: email.value ?? undefined
      })
    }
  })

  async function logout(): Promise<void> {
    const { error } = await supabase.auth.signOut()
    // https://github.com/orgs/supabase/discussions/23715
    if (error?.name === 'AuthSessionMissingError') {
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i)
        if (key?.includes('auth-token')) {
          localStorage.removeItem(key)
        }
      }
    } else if (error) {
      throw error
    }

    user.value = null
  }

  async function login(email: string, password: string): Promise<void> {
    const { error } = await supabase.auth.signInWithPassword({ email, password })
    if (error) throw error
  }

  async function signup(email: string, password: string): Promise<void> {
    const { error } = await supabase.auth.signUp({ email, password, options: { emailRedirectTo: `${window.location.origin}/confirm-email/success` } })
    if (error) throw error
  }

  async function requestPasswordReset(email: string): Promise<void> {
    const { error } = await supabase.auth.resetPasswordForEmail(email, { redirectTo: `${window.location.origin}/reset-password` })
    if (error) throw error
  }

  async function changePassword(password: string): Promise<void> {
    const { error } = await supabase.auth.updateUser({ password })
    if (error) throw error
  }

  async function initAuthStore(): Promise<void> {
    const { data, error } = await supabase.auth.getSession()
    if (error) throw error
    if (!data.session?.user) throw new Error('User not logged in')
    user.value = data.session.user
  }

  async function resendConfirmationEmail(email: string): Promise<void> {
    const { error } = await supabase.auth.resend({ type: 'signup', email })
    if (error) throw error
  }

  return {
    userId,
    email,
    isAdmin,
    isLoggedIn,
    hasRole,
    logout,
    login,
    signup,
    requestPasswordReset,
    initAuthStore,
    resendConfirmationEmail,
    changePassword
  }
})
