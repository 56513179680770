import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

import notificationsApi from '@/api/database/notifications-api'
import type { NotificationCounts } from '@/types/supabase'

export const useNotificationsStore = defineStore('notifications', () => {
  const notificationCounts = ref<NotificationCounts | null>(null)

  const newConnectionsNotifications = computed(() => notificationCounts.value?.find(n => n.dimension === 'NETWORK_NEW_CONNECTIONS')?.count ?? 0)
  const newConnectionRequestsNotifications = computed(() => notificationCounts.value?.find(n => n.dimension === 'NETWORK_NEW_CONNECTION_REQUESTS')?.count ?? 0)
  const newDealersNotifications = computed(() => notificationCounts.value?.find(n => n.dimension === 'NETWORK_NEW_DEALERS')?.count ?? 0)
  const feedbackMessagesNotifications = computed(() => notificationCounts.value?.find(n => n.dimension === 'FEEDBACK_NEW_MESSAGES')?.count ?? 0)
  const newUsersNotifications = computed(() => notificationCounts.value?.find(n => n.dimension === 'PROFILES_NEW_USERS')?.count ?? 0)
  const myNetworkNotifications = computed(() => newConnectionsNotifications.value + newConnectionRequestsNotifications.value + newDealersNotifications.value)
  const newCarsNotifications = computed(() => notificationCounts.value?.find(n => n.dimension === 'CARS_NEW_CARS')?.count ?? 0)
  const allNotifications = computed(() => notificationCounts.value?.reduce((acc, curr) => acc + curr.count, 0) ?? 0)

  const fetchNotificationCounts = async (): Promise<void> => {
    notificationCounts.value = await notificationsApi.fetchNotificationCounts()
  }

  return {
    newConnectionsNotifications,
    newConnectionRequestsNotifications,
    newDealersNotifications,
    feedbackMessagesNotifications,
    newUsersNotifications,
    myNetworkNotifications,
    allNotifications,
    newCarsNotifications,
    fetchNotificationCounts
  }
})
