<template>
  <div
    aria-label="table-pagination"
    class="flex items-center justify-between pt-4 border-t-2 border-gray-900/10"
  >
    <div class="flex flex-1 justify-between sm:hidden">
      <CarvisButton
        class="w-fit text-sm"
        :disabled="page === 1"
        :loading="loading"
        @click="$emit('update:page', page - 1)"
      >
        Vorherige
      </CarvisButton>
      <div class="flex justify-center text-xs text-gray-700 items-center">
        <span v-if="count != 0">
          {{ page }} / {{ lastPage }}
        </span>
      </div>
      <CarvisButton
        class="w-fit text-sm"
        :disabled="count === null || limit === null || count <= (page + 1) * limit"
        :loading="loading"
        @click="$emit('update:page', page + 1)"
      >
        Nächste
      </CarvisButton>
    </div>
    <div class="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
      <div
        v-if="loading"
        class="h-2 bg-gray-300 rounded animate-pulse w-52"
      />
      <p
        v-else-if="count !== null && count > 0"
        class="text-sm text-gray-700"
      >
        Zeige
        <span class="font-medium">{{ firstItemOnPage }}</span>
        bis
        <span class="font-medium">{{ lastItemOnPage }}</span>
        von insgesamt
        <span class="font-medium">{{ count ?? 0 }}</span>
        {{ itemDescription }}.
      </p>
      <p v-else />
      <nav class="isolate inline-flex -space-x-px rounded-md shadow-sm">
        <PaginationNavigation
          back
          :disabled="page === 1"
          @click="$emit('update:page', page - 1)"
        />
        <PaginationPage
          v-for="i in Math.max(lastPage, 0)"
          :key="i"
          :current-page="page"
          :page="i"
          @click="$emit('update:page', $event)"
        />
        <PaginationNavigation
          :disabled="count === null || limit === null || count <= page * limit"
          next
          @click="$emit('update:page', page + 1)"
        />
      </nav>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import PaginationNavigation from '@/components/table/PaginationNavigation.vue'
import PaginationPage from '@/components/table/PaginationPage.vue'

const props = defineProps<{
  page: number
  limit: number | null
  count: number | null
  loading?: boolean
  itemDescription: string
}>()

defineEmits(['update:page'])

const firstItemOnPage = computed(() => {
  if (props.limit === null) return 1
  if (props.count === null) return 0
  return ((props.page - 1) * props.limit) + 1
})

const lastItemOnPage = computed(() => {
  if (props.limit === null) return props.count
  if (props.count === null) return 0
  return Math.min(props.count, props.page * props.limit)
})

const lastPage = computed(() => {
  if (props.count === null) return 0
  if (props.limit === null) return 1
  return Math.ceil(props.count / props.limit)
})
</script>
