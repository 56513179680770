import { type NavigationGuard, type NavigationGuardNext, type RouteLocationNormalized } from 'vue-router'

import { useAuthStore } from '@/stores/auth-store'

export const authGuard: NavigationGuard = async (
  to: RouteLocationNormalized,
  _from: RouteLocationNormalized,
  next: NavigationGuardNext
): Promise<void> => {
  const authStore = useAuthStore()

  // try to init users who already have a session
  try {
    await authStore.initAuthStore()
    if (authStore.userId !== null && to.path === '/') {
      return next({ path: '/dashboard', replace: true })
    }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (error) {
    // swallow exception
  }

  // forward to page if no role is required
  const requiredRole = to.meta?.requiresRole
  if (!requiredRole) {
    return next()
  }

  // at this point a role is required.
  // if user is logged in, redirect to login page
  try {
    await authStore.initAuthStore()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (error) {
    return next({ path: '/login', query: { redirect: to.fullPath } })
  }

  // Admins have access to everything
  if (authStore.hasRole('admin')) {
    return next()
  }

  // Dispatch to 'forbidden' on missing permission
  if (!authStore.hasRole(requiredRole)) {
    return next({ path: '/forbidden', query: { requiredRole } })
  }

  // otherwise forward
  return next()
}
