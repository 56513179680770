<template>
  <div class="w-full px-4 md:px-6 lg:px-8">
    <div
      v-if="$slots.logo"
      class="mb-12 sm:mb-28"
    >
      <slot name="logo" />
    </div>
    <div class="sm:px-0 sm:max-w-sm space-y-4">
      <CarvisH1>
        <slot name="title" />
      </CarvisH1>
      <slot />
    </div>
  </div>
</template>
