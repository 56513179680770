<template>
  <div
    v-if="breadcrumbs.length"
    class="flex items-center text-gray-900 text-sm pb-2 lg:pb-4 outer-page-padding-x wide-page-max-width"
  >
    <CarvisLink
      class="hover:!text-blue-dark"
      to="/dashboard"
    >
      <HomeOutline :size="24" />
    </CarvisLink>

    <template
      v-for="breadcrumb in breadcrumbs"
      :key="breadcrumb.to"
    >
      <ChevronRight
        class="mx-1"
        :size="24"
      />
      <CarvisLink
        class="!font-light hover:!text-blue-dark hover:!font-medium"
        :to="breadcrumb.to"
      >
        {{ breadcrumb.text }}
      </CarvisLink>
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import ChevronRight from 'vue-material-design-icons/ChevronRight.vue'
import HomeOutline from 'vue-material-design-icons/HomeOutline.vue'
import { useRoute } from 'vue-router'

const route = useRoute()

const breadcrumbs = computed(() => route.meta?.breadcrumbs?.call(this, route) ?? [])
</script>

<style scoped>
* > .router-link-active {
  @apply !font-medium;
  @apply text-blue-dark;
}
</style>
